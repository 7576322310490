@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');

img {
  user-select: none
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  font-family: 'Noto Sans TC', sans-serif;
}

.Nav {
  height: 8vh;
  max-height: 8vh;
  min-height: 8vh;
}

#nav-dropdown {
  color: #fff !important;
}

.oneLineEllipsis {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.textShadow {
  box-shadow: none;
  text-shadow: rgb(87, 179, 255) 0.1em 0.1em 0.2em;
  border-radius: 50%;
  cursor: grab;
}

.boxShadow {
  box-shadow: rgb(178, 178, 178) 0.1em 0.1em 0.2em;
}

.dropdown-item.active {
  background-color: #002a4c9e !important;
}

.dropdown-item:hover {
  color: #fff !important;
  background-color: #002a4c9e !important;
  cursor: pointer;
}

.breadcrumbs-two {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.breadcrumbs-two > li {
  float: left;
  width: 20%;
}

.breadcrumbs-two > li > a {
  display: flex;
  text-transform: uppercase;
  float: left;
  text-decoration: none;
  color: #FFF;
  position: relative;
  text-align: center;
  width: 100%;
  height: 60px;
  line-height: 1.3;
  font-size: 15px;
}

.breadcrumbs-two > li > a > span {
  display: inline-block;
  max-width: 100%;
  width: 150px;
  margin: auto;
  position: relative;
  color: white;
  font-size: 1.25rem;
  /* top: 20px; */
}

.breadcrumbs-two > li > a::before {
  content: "";
  position: absolute;
  top: 68%;
  margin-top: -41px;
  border-width: 30px 0 30px 30px;
  border-style: solid;
  border-color: #ddd #ddd #ddd transparent;
  left: -30px;
}

.breadcrumbs-two > li > a::after {
  content: "";
  position: absolute;
  top: 67%;
  margin-top: -40px;
  border-top: 40px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid #ddd;
  right: -30px;
}

.bread1 > a {
  background-color: #b9e473;
}

.bread1 > a::before {
  border-color: #b9e473 #b9e473 #b9e473 transparent !important;
}

.bread1 > a::after {
  border-color: transparent #b9e473 transparent #b9e473 !important;
}

.bread2 > a {
  background-color: #9fdd80;
}

.bread2 > a::before {
  border-color: #9fdd80 #9fdd80 #9fdd80 transparent !important;
}

.bread2 > a::after {
  border-color: transparent #9fdd80 transparent #9fdd80 !important;
}

.bread3 > a {
  background-color: #7fd48f;
}

.bread3 > a::before {
  border-color: #7fd48f #7fd48f #7fd48f transparent !important;
}

.bread3 > a::after {
  border-color: transparent #7fd48f transparent #7fd48f !important;
}

.bread4 > a {
  background-color: #63cb9d;
}

.bread4 > a::before {
  border-color: #63cb9d #63cb9d #63cb9d transparent !important;
}

.bread4 > a::after {
  border-color: transparent #1f7171 transparent #1f7171 !important;
}

.bread5 > a {
  background-color: #3ec3ab;
}

.bread5 > a::before {
  border-color: #3ec3ab #3ec3ab #3ec3ab transparent !important;
}

.bread1 > a::before {
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-left: 0 !important;
}

.bread4 > a::after {
  right: -34px !important;
}

.bread5 > a::after {
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-left: 0 !important;
}

.text-chelonia {
  color: #002a4c !important;
}

.text-chelonia-light {
  color: #0c4777 !important;
}

.text-chelonia-lighter {
  color: #6088a9 !important;
}

.btn-select-dark {
  color: #0c4777 !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

.btn-select-light {
  color: #a8a8a8 !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

.btn-select-dark:hover {
  color: #0c4777!important;
}

.btn-select-light:hover {
  color: #002a4c !important;
}

.text-icon {
  color: #002a4c !important;
  cursor: pointer !important;
}

.text-icon:hover, .text-icon:active, .text-icon:focus {
  color: #0c4777 !important;
  cursor: pointer !important;
}

.text-icon-on {
  color: #0c4777 !important;
  cursor: pointer,
}

.text-icon2 {
  color: #002a4c !important;
  cursor: pointer !important;
}

.text-icon2:hover, .text-icon2:active, .text-icon2:focus {
  color: #f3db4f !important;
  cursor: pointer !important;
}

.text-icon2-on {
  color: #f3db4f !important;
  cursor: pointer,
}

.text-grey {
  color: #bdbdbd !important;
}

.check-chelonia {
  color: #68bc99 !important;
}

.btn-chelonia {
  color: #002a4c !important;
  background-color: #cadbe8 !important;
  border-color: #cadbe8 !important;
}

.btn-chelonia:hover, .btn-chelonia:active {
  color: #00182c !important;
  background-color: #bbccda !important;
  border-color: #bbccda !important;
}

.btn-chelonia-dark {
  color: #fff !important;
  background-color: #1c4c73 !important;
  border-color: #1c4c73 !important;
}

.btn-chelonia-dark:hover, .btn-chelonia:active {
  color: #00182c !important;
  background-color: #bbccda !important;
  border-color: #bbccda !important;
}

.btn-outline-chelonia {
  color: #1c4c73 !important;
  background-color: transparent !important;
  border-color: #1c4c73 !important;
}

.btn-outline-chelonia:hover, .btn-outline-chelonia:active {
  color: #fff !important;
  background-color: #1c4c73 !important;
  border-color: #1c4c73 !important;
}

.btn-chelonia2 {
  color: #093731 !important;
  background-color: #88d7b6 !important;
  border-color: #88d7b6 !important;
}

.btn-chelonia2:hover, .btn-chelonia2:active {
  color: #536a28 !important;
  background-color: #bde570 !important;
  border-color: #bde570 !important;
}

.btn-chelonia3 {
  color: #fff !important;
  background-color: #115c52 !important;
  border-color: #115c52 !important;
}

.btn-chelonia3:hover, .btn-chelonia2:active {
  color: #093731 !important;
  background-color: #88d7b6 !important;
  border-color: #88d7b6 !important;
}

.btn-outline-chelonia2 {
  color: #286a65 !important;
  background-color: transparent !important;
  border-color: #286a65 !important;
}

.btn-outline-chelonia2:hover, .btn-outline-chelonia2:active {
  color: #286a65 !important;
  background-color: #60ca9e !important;
  border-color: #60ca9e !important;
}

.btn-edit {
  color: #002a4c !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-edit:hover, .btn-edit:active {
  color: #266faa !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-lightgrey {
  color: #848484 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-lightgrey:hover, .btn-lightgrey:active {
  color: #5b5b5b !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-red {
  color: #49130c !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-red:hover, .btn-red:active {
  color: #c26f6f !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-send {
  color: #0c492c !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-send:hover, .btn-send:active {
  color: #217e51 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-outline-red {
  color: #49130c !important;
  background-color: transparent !important;
  border-color: #49130c !important;
}

.btn-youtube {
  color: #a01010 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-youtube:hover, .btn-youtube:active {
  color: #5d0909 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-outline-red:hover, .btn-outline-red:active {
  color: #ffffff !important;
  background-color: #d1a4a4 !important;
  border-color: #d1a4a4 !important;
}

.bg-darkblue {
  background-color: #3B5192 !important;
}

.bg-lightblue {
  background-color: #84DFF6 !important;
}

.bg-black {
  background-color: #000000 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-red {
  background-color: #DC0008 !important;
}

.bg-yellow {
  background-color: #FFC431 !important;
}

.bg-01 {
  background-image: url(./assets/images/bg_01.png);
}

.bg-02 {
  background-image: url(./assets/images/bg_02.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}

.bg-chelonia-linear {
  background: linear-gradient(to top, #38c2ab, #bae571) !important;
}

.bg-chelonia {
  background-color: #cddfed !important;
}

.bg-chelonia2 {
  background-color: #002a4c !important;
}

.bg-chelonia-mid {
  background-color: #e4e6e5 !important;
}

.bg-chelonia-light {
  background-color: #f4fcf8 !important;
}

.h-20 {
  height: 20% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-82 {
  height: 82% !important;
}

.h-85 {
  height: 85% !important;
}

.h-97 {
  height: 97% !important;
}

.w-8 {
  width: 8% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-22 {
  width: 22% !important;
}

.w-27 {
  width: 27% !important;
}

.w-30 {
  width: 30% !important;
}

.w-32 {
  width: 32% !important;
}

.w-40 {
  width: 40% !important;
}

.w-48 {
  width: 48% !important;
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

.modal-table > .container > .row > div:nth-child(2) > p {
  text-align: start !important;
}

.tooltip-inner {
  min-width: 45rem !important;
  width: 45rem !important;
}

.tooltip-inner > img {
  min-width: 40rem !important;
  width: 40rem !important;
}

.border-table > .row {
  flex-wrap: nowrap;
  font-size: xx-small;
  text-align: center;
}

.border-table > .row > .col {
  text-align: center;
  padding: 0;
}

.border-table > .row > div > .row {
  text-align: center;
  justify-content: center;
  padding: 0;
}

.border-table > .row > * {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  padding: 0;
}

.no-border-top {
  border-top: 0px solid;
}

.no-border-bottom {
  border-bottom: 0px solid;
}

.no-border-right {
  border-right: 0px solid;
}

.no-border-left {
  border-left: 0px solid;
}

.scrollbarShow::-webkit-scrollbar {
  width: .6em !important;
  height: 0em !important;
}

.scrollbarShow::-webkit-scrollbar-button {
  background: transparent !important;
}

.scrollbarShow::-webkit-scrollbar-track-piece {
  background: transparent !important;
}

.scrollbarShow::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent !important;
}

.scrollbarShow::-webkit-scrollbar-thumb {
  background-color: #5d5275 !important;
  border-radius: 50rem !important;
}

.fs-7 {
  font-size: smaller;
}

.tab-content {
  overflow-y: scroll;
  overflow-x: hidden;
}

.input-container {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    padding: .4em .5em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,border-color .1s ease;
    font-size: 13px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: flex-start;
    overflow: scroll;
}

.input-container > input {
  border: none;
}

.input-container > input:focus {
  border: none;
  box-shadow: none;
}

.input-tag {
    line-height: 1;
    vertical-align: baseline;
    margin: .14285714em;
    background-color: #f3f3f3;
    background-image: none;
    padding: .3833em .833em;
    color: rgba(0,0,0,.6);
    text-transform: none;
    font-weight: 600;
    border: 0 solid transparent;
    border-radius: .28571429rem;
    transition: background .1s ease;
    font-size: .8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.react_time_range__time_range_container {
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.react_time_range__time_range_container > div {
  height: 100% !important;
}

.react_time_range__time_range_container > div > * {
  transform: translate(0%, 0%) !important;
  height: 100% !important;
}

.react_time_range__time_range_container > div > .react_time_range__handle_container{
  transform: translate(-50%, 50%) !important;
  height: 50% !important;
  margin: 0 auto !important;
  pointer-events: none;
}

.react_time_range__time_range_container > div > .react_time_range__track {
  background-color: #266faa !important;
  border-color: #266faa !important;
  opacity: 0.2 !important;
}

.react_time_range__time_range_container > div > .react_time_range__rail__inner {
  background-color: #266faa !important;
  opacity: 0.2 !important;
}

.tab-content {
  height: 90% !important;
}

#name_edit_form {
  padding: 0 !important;
  height: 75% !important;
  margin-top: auto;
  margin-bottom: auto;
}

.video_uploader {
  height: 100% !important;
  width: 100% !important;
}